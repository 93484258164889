import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";

// material-ui icons
import Assignment from "@material-ui/icons/Assignment";
// import Person from "@material-ui/icons/Person";
import Edit from "@material-ui/icons/Edit";
import Close from "@material-ui/icons/Close";
import VisibilityIcon from "@material-ui/icons/Visibility";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Table from "components/Table/Table.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";

import styles from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.js";

const useStyles = makeStyles(styles);

export default function ExtendedTables(props) {
  // const [checked, setChecked] = React.useState([]);
  // const handleToggle = value => {
  //   const currentIndex = checked.indexOf(value);
  //   const newChecked = [...checked];

  //   if (currentIndex === -1) {
  //     newChecked.push(value);
  //   } else {
  //     newChecked.splice(currentIndex, 1);
  //   }
  //   setChecked(newChecked);
  // };
  const classes = useStyles();
  const fillButtons = [
    // { color: "info", icon: Person },
    { color: "info", icon: Edit, url: "manage-event" },
    { color: "danger", icon: Close }
  ].map((prop, key) => {
    return (
      <Button
        color={prop.color}
        className={classes.actionButton}
        key={key}
        href={prop.url}
      >
        <prop.icon className={classes.icon} />
      </Button>
    );
  });

  function goToReservationList() {
    props.history.push("/admin/reservations");
  }

  return (
    <GridContainer>
      <GridItem xs={12}>
        <div style={{ textAlign: "right" }}>
          <Link to="/admin/manage-event">
            <Button color="info">Add Event</Button>
          </Link>
        </div>
        <Card>
          <CardHeader color="info" icon>
            <CardIcon color="info">
              <Assignment />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>Event Details</h4>
          </CardHeader>
          <CardBody>
            <Table
              tableHead={[
                "#",
                "Event Name",
                "Event Type",
                "Event Price",
                "Reservation",
                "View Reservation",
                "Actions"
              ]}
              tableData={[
                [
                  "1",
                  "Wedding",
                  "Private",
                  "$35",
                  30,
                  <Button
                    className={classes.actionButton}
                    onClick={goToReservationList}
                  >
                    <VisibilityIcon />
                  </Button>,
                  fillButtons
                ],
                [
                  "2",
                  "Christmas",
                  "Private",
                  "$35",
                  30,
                  <Button
                    className={classes.actionButton}
                    onClick={goToReservationList}
                  >
                    <VisibilityIcon />
                  </Button>,
                  fillButtons
                ],
                [
                  "3",
                  "PhotoShoot",
                  "Public",
                  "$35",
                  30,
                  <Button
                    className={classes.actionButton}
                    onClick={goToReservationList}
                  >
                    <VisibilityIcon />
                  </Button>,
                  fillButtons
                ],
                [
                  "4",
                  "Halloween",
                  "Public",
                  "$35",
                  30,
                  <Button
                    className={classes.actionButton}
                    onClick={goToReservationList}
                  >
                    <VisibilityIcon />
                  </Button>,
                  fillButtons
                ],
                [
                  "5",
                  "Thanks Giving",
                  "Public",
                  "$35",
                  30,
                  <Button
                    className={classes.actionButton}
                    onClick={goToReservationList}
                  >
                    <VisibilityIcon />
                  </Button>,
                  fillButtons
                ]
              ]}
              customCellClasses={[
                classes.center,
                classes.right,
                classes.center,
                classes.right
              ]}
              customClassesForCells={[0, 4, 5, 6]}
              customHeadCellClasses={[
                classes.center,
                classes.right,
                classes.center,
                classes.right
              ]}
              customHeadClassesForCells={[0, 4, 5, 6]}
            />
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}
