import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import FormLabel from "@material-ui/core/FormLabel";
import PropTypes from "prop-types";

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardText from "components/Card/CardText.js";
import CardBody from "components/Card/CardBody.js";

import styles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";

import { TextField, MenuItem } from "@material-ui/core";
import CardFooter from "components/Card/CardFooter";

class AddEvents extends Component {
  constructor(props) {
    super(props);
    this.state = {
      addonsName: "",
      addonsCategories: ["Wedding", "PhotoShoot", "Other"],
      price: "",
      addonsCategory: "",
      priceType: "",
      priceTypes: ["Per Person", "Full"]
    };
  }

  handleChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  manageEvent = () => {
    this.props.history.push("/admin/event-details");
  };

  render() {
    const { classes } = this.props;
    const {
      addonsName,
      addonsCategories,
      // price,
      addonsCategory,
      priceTypes
    } = this.state;
    return (
      <>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="info" text>
                <CardText color="info">
                  <h4 className={classes.cardTitle}>Manage Addons</h4>
                </CardText>
              </CardHeader>
              <CardBody className={classes.subscriptionCard}>
                <form>
                  <GridContainer>
                    <GridItem xs={12} sm={2}>
                      <FormLabel className={classes.labelHorizontal} style={{ paddingTop: 20 }}>
                        Addons Name
                      </FormLabel>
                    </GridItem>
                    <GridItem xs={12} sm={10}>
                      <TextField
                        fullWidth
                        label="Please Enter Addons Name"
                        name="addonsName"
                        margin="normal"
                        variant="outlined"
                        value={addonsName}
                        onChange={this.handleChange}
                      />
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem xs={12} sm={2}>
                      <FormLabel
                        className={classes.labelHorizontal}
                        style={{ paddingTop: 20 }}
                      >
                        Addons Category
                      </FormLabel>
                    </GridItem>
                    <GridItem xs={12} sm={10}>
                      <TextField
                        fullWidth
                        select
                        name="addonsCategory"
                        variant="outlined"
                        margin="normal"
                        label="Please Select Addons Type"
                        value={addonsCategory}
                        onChange={this.handleChange}
                      >
                        {addonsCategories.map(val => (
                          <MenuItem value={val}>{val}</MenuItem>
                        ))}
                      </TextField>
                    </GridItem>
                  </GridContainer>
                  <GridContainer style={{ marginTop: 10 }}>
                    <GridItem xs={12} sm={2}>
                      <FormLabel
                        className={classes.labelHorizontal}
                        style={{ paddingTop: 20 }}
                      >
                        Price
                      </FormLabel>
                    </GridItem>
                    <GridItem xs={12} sm={10}>
                      <TextField
                        fullWidth
                        label="Please Enter Price"
                        name="addonsName"
                        margin="normal"
                        variant="outlined"
                        value={addonsName}
                        onChange={this.handleChange}
                      />
                    </GridItem>
                  </GridContainer>
                  <GridContainer style={{ marginTop: 10 }}>
                    <GridItem xs={12} sm={2}>
                      <FormLabel
                        className={classes.labelHorizontal}
                        style={{ paddingTop: 20 }}
                      >
                        Price Type
                      </FormLabel>
                    </GridItem>
                    <GridItem xs={12} sm={10}>
                      <TextField
                        fullWidth
                        select
                        name="addonsCategory"
                        variant="outlined"
                        margin="normal"
                        label="Please Select Price Type"
                        value={addonsCategory}
                        onChange={this.handleChange}
                      >
                        {priceTypes.map(val => (
                          <MenuItem value={val}>{val}</MenuItem>
                        ))}
                      </TextField>
                    </GridItem>
                  </GridContainer>
                </form>
              </CardBody>
              <CardFooter style={{ justifyContent: "flex-end" }}>
                <Button color="info" onClick={this.manageEvent}>
                  Add Addons
                </Button>
              </CardFooter>
            </Card>
          </GridItem>
        </GridContainer>
      </>
    );
  }
}
AddEvents.propTypes = {
  classes: PropTypes.object.isRequired
};
export default withStyles(styles)(AddEvents);
