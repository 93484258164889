import React, { Component } from "react";
import {  withStyles } from "@material-ui/core/styles";
import FormLabel from "@material-ui/core/FormLabel";
import PropTypes from "prop-types";
import Tooltip from "@material-ui/core/Tooltip";
import Remove from "@material-ui/icons/Delete";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardText from "components/Card/CardText.js";
import CardBody from "components/Card/CardBody.js";

import styles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import EventImage1 from "assets/img/event-1.jpg";
import EventImage2 from "assets/img/event-2.jpg";
import EventImage3 from "assets/img/photoshoot-3.jpg";
import EventImage4 from "assets/img/photoshoot-4.jpg";
import {
  TextField,
  FormControlLabel,
  Checkbox,
  MenuItem
} from "@material-ui/core";
import CardFooter from "components/Card/CardFooter";

class AddEvents extends Component {
  constructor(props) {
    super(props);
    this.state = {
      eventName: "",
      eventDate: new Date(),
      eventCategory: "",
      eventPhotos: [],
      eventDescription: "",
      eventType: "",
      eventTypes: ["public", "private"],
      isChargeable: false,
      entryFees: ""
    };
  }

  handleChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  handleEventDateChange = date => {
    this.setState({
      eventDate: date
    });
  };

  handleChargeableChange = () => {
    this.setState(prevState => ({
      isChargeable: !prevState.isChargeable
    }));
  };

  manageEvent = () => {
    this.props.history.push("/admin/event-details")
  };

  handleEventDescriptionChange = e => {
    this.setState({
      eventDescription: e
    });
  };

  render() {
    const { classes } = this.props;
    const {
      eventName,
      eventType,
      eventTypes,
      eventDate,
      isChargeable,
      entryFees,
      eventDescription,
      eventPhotos
    } = this.state;
    return (
      <>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="info" text>
                <CardText color="info">
                  <h4 className={classes.cardTitle}>Manage Event</h4>
                </CardText>
              </CardHeader>
              <CardBody className={classes.subscriptionCard}>
                <form>
                  <GridContainer>
                    <GridItem xs={12} sm={2}>
                      <FormLabel className={classes.labelHorizontal}>
                        Event Name
                      </FormLabel>
                    </GridItem>
                    <GridItem xs={12} sm={10}>
                      <TextField
                        fullWidth
                        label="Please Enter Event Name"
                        name="eventName"
                        margin="normal"
                        variant="outlined"
                        value={eventName}
                        onChange={this.handleChange}
                      />
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem xs={12} sm={2}>
                      <FormLabel
                        className={classes.labelHorizontal}
                        style={{ paddingTop: 20 }}
                      >
                        Event Type
                      </FormLabel>
                    </GridItem>
                    <GridItem xs={12} sm={10}>
                      <TextField
                        fullWidth
                        select
                        name="eventType"
                        variant="outlined"
                        label="Please Select Event Type"
                        value={eventType}
                        onChange={this.handleChange}
                      >
                        {eventTypes.map(val => (
                          <MenuItem value={val}>{val}</MenuItem>
                        ))}
                      </TextField>
                    </GridItem>
                  </GridContainer>
                  <GridContainer style={{ marginTop: 10 }}>
                    <GridItem xs={12} sm={2}>
                      <FormLabel className={classes.labelHorizontal} style={{ paddingTop: 20 }}>
                        Event Date
                      </FormLabel>
                    </GridItem>
                    <GridItem xs={12} sm={10}>
                      <MuiPickersUtilsProvider utils={MomentUtils}>
                        <DatePicker
                          fullWidth
                          inputVariant={"outlined"}
                          format={"MM/DD/YYYY"}
                          variant={"inline"}
                          value={eventDate}
                          onChange={this.handleEventDateChange}
                          autoOk
                          disablePast
                        />
                      </MuiPickersUtilsProvider>
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem xs={12} sm={2}>
                      <FormLabel className={classes.labelHorizontal}>
                        Chargeable
                      </FormLabel>
                    </GridItem>
                    <GridItem xs={12} sm={10}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={isChargeable}
                            onChange={this.handleChargeableChange}
                            value="isChargeable"
                            inputProps={{
                              "aria-label": "primary checkbox"
                            }}
                            style={{
                              paddingTop: 20
                            }}
                          />
                        }
                        // label="Discount"
                      />
                    </GridItem>
                  </GridContainer>
                  {isChargeable && (
                    <>
                      <GridContainer>
                        <GridItem xs={12} sm={2}>
                          <FormLabel className={classes.labelHorizontal}>
                            Entry Fees
                          </FormLabel>
                        </GridItem>
                        <GridItem xs={12} sm={10}>
                          <TextField
                            fullWidth
                            label="Please Enter Entry Fees"
                            name="entryFees"
                            margin="normal"
                            variant="outlined"
                            value={entryFees}
                            onChange={this.handleChange}
                          />
                        </GridItem>
                      </GridContainer>
                    </>
                  )}
                  <GridContainer>
                    <GridItem xs={12} sm={2}>
                      <FormLabel className={classes.labelHorizontal}>
                        Event Description
                      </FormLabel>
                    </GridItem>
                    <GridItem xs={12} sm={10}>
                      <ReactQuill
                        value={eventDescription}
                        onChange={this.handleEventDescriptionChange}
                        theme="snow"
                      />
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem xs={12} sm={2}>
                      <FormLabel className={classes.labelHorizontal}>
                        Event Images
                      </FormLabel>
                    </GridItem>
                    <GridItem xs={12} sm={10}>
                      <TextField
                        fullWidth
                        type="file"
                        // label="Please Select Images"
                        name="eventPhotos"
                        margin="normal"
                        variant="outlined"
                        value={eventPhotos}
                        onChange={this.handleChange}
                      />
                    </GridItem>
                  </GridContainer>
                </form>
              </CardBody>
              <CardFooter style={{ justifyContent: "flex-end" }}>
                <Button color="info" onClick={this.manageEvent}>
                  Add Event
                </Button>
              </CardFooter>
            </Card>
          </GridItem>
        </GridContainer>

        <GridContainer>
          <GridItem xs={12} sm={12} md={3}>
            <Card product className={classes.cardHover}>
              <CardHeader image className={classes.cardHeaderHover}>
                <a href="#pablo" onClick={e => e.preventDefault()}>
                  <img src={EventImage1} alt="..." />
                </a>
              </CardHeader>
              <CardBody>
                <div className={classes.RemoveButton}>
                  <Tooltip
                    id="tooltip-top"
                    title="Remove"
                    placement="bottom"
                    classes={{ tooltip: classes.tooltip }}
                  >
                    <Button color="danger" simple justIcon>
                      <Remove className={classes.underChartIcons} />
                    </Button>
                  </Tooltip>
                </div>
              </CardBody>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={3}>
            <Card product className={classes.cardHover}>
              <CardHeader image className={classes.cardHeaderHover}>
                <a href="#pablo" onClick={e => e.preventDefault()}>
                  <img src={EventImage2} alt="..." />
                </a>
              </CardHeader>
              <CardBody>
                <div className={classes.RemoveButton}>
                  <Tooltip
                    id="tooltip-top"
                    title="Remove"
                    placement="bottom"
                    classes={{ tooltip: classes.tooltip }}
                  >
                    <Button color="danger" simple justIcon>
                      <Remove className={classes.underChartIcons} />
                    </Button>
                  </Tooltip>
                </div>
              </CardBody>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={3}>
            <Card product className={classes.cardHover}>
              <CardHeader image className={classes.cardHeaderHover}>
                <a href="#pablo" onClick={e => e.preventDefault()}>
                  <img src={EventImage3} alt="..." />
                </a>
              </CardHeader>
              <CardBody>
                <div className={classes.RemoveButton}>
                  <Tooltip
                    id="tooltip-top"
                    title="Remove"
                    placement="bottom"
                    classes={{ tooltip: classes.tooltip }}
                  >
                    <Button color="danger" simple justIcon>
                      <Remove className={classes.underChartIcons} />
                    </Button>
                  </Tooltip>
                </div>
              </CardBody>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={3}>
            <Card product className={classes.cardHover}>
              <CardHeader image className={classes.cardHeaderHover}>
                <a href="#pablo" onClick={e => e.preventDefault()}>
                  <img src={EventImage4} alt="..." />
                </a>
              </CardHeader>
              <CardBody>
                <div className={classes.RemoveButton}>
                  <Tooltip
                    id="tooltip-top"
                    title="Remove"
                    placement="bottom"
                    classes={{ tooltip: classes.tooltip }}
                  >
                    <Button color="danger" simple justIcon>
                      <Remove className={classes.underChartIcons} />
                    </Button>
                  </Tooltip>
                </div>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </>
    );
  }
}
AddEvents.propTypes = {
  classes: PropTypes.object.isRequired
};
export default withStyles(styles)(AddEvents);
