import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import FormLabel from "@material-ui/core/FormLabel";
import { TextField } from "@material-ui/core";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardText from "components/Card/CardText.js";
import CardBody from "components/Card/CardBody.js";

import styles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import CardFooter from "components/Card/CardFooter";

const useStyles = makeStyles(styles);

export default function RegularForms() {
  // const [checked, setChecked] = React.useState([24, 22]);
  // const [selectedEnabled, setSelectedEnabled] = React.useState("b");
  // const [selectedValue, setSelectedValue] = React.useState(null);
  // const handleChange = event => {
  //   setSelectedValue(event.target.value);
  // };
  // const handleChangeEnabled = event => {
  //   setSelectedEnabled(event.target.value);
  // };
  // const handleToggle = value => {
  //   const currentIndex = checked.indexOf(value);
  //   const newChecked = [...checked];

  //   if (currentIndex === -1) {
  //     newChecked.push(value);
  //   } else {
  //     newChecked.splice(currentIndex, 1);
  //   }
  //   setChecked(newChecked);
  // };
  const classes = useStyles();
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="info" text>
            <CardText color="info">
              <h4 className={classes.cardTitle}>Manage Reservation</h4>
            </CardText>
          </CardHeader>
          <CardBody>
            <form>
              <GridContainer>
                <GridItem xs={12} sm={2}>
                  <FormLabel className={classes.labelHorizontal}>
                    Name
                  </FormLabel>
                </GridItem>
                <GridItem xs={12} sm={10}>
                  <TextField
                    fullWidth
                    label="Please Enter Name"
                    name="name"
                    margin="normal"
                    variant="outlined"
                    // value={entryFees}
                    // onChange={this.handleChange}
                  />
                </GridItem>
              </GridContainer>
            </form>
          </CardBody>
          <CardFooter style={{ justifyContent: "flex-end" }}>
            <Button color="info">Add Event</Button>
          </CardFooter>
        </Card>
      </GridItem>
    </GridContainer>
  );
}
