import Dashboard from "views/Dashboard/Dashboard.js";
// import ErrorPage from "views/Pages/ErrorPage.js";
// import ExtendedForms from "views/Forms/ExtendedForms.js";
import EventDetails from "views/Tables/EventDetails.js";
import RoomDetails from "views/Rooms/RoomDetails.js";

import ManageEvents from "views/Forms/ManageEvents.js";
import AddRoom from "views/Rooms/AddRoom.js";
import ManageAddons from "views/EventAddons/ManageEventAddons";
import AddonsList from "views/EventAddons/EventAddonsList";

// import RegularTables from "views/Tables/RegularTables.js";
// import SweetAlert from "views/Components/SweetAlert.js";
// import TimelinePage from "views/Pages/Timeline.js";
// import Typography from "views/Components/Typography.js";
// import UserProfile from "views/Pages/UserProfile.js";
// import ValidationForms from "views/Forms/ValidationForms.js";
// import VectorMap from "views/Maps/VectorMap.js";
// import Widgets from "views/Widgets/Widgets.js";
// import Wizard from "views/Forms/Wizard.js";
import Reservations from "views/Reservations/Reservations.js";
import AddReservation from "views/Reservations/AddReservation.js";

// @material-ui/icons
// import Apps from "@material-ui/icons/Apps";
import DashboardIcon from "@material-ui/icons/Dashboard";
// import DateRange from "@material-ui/icons/DateRange";
// import GridOn from "@material-ui/icons/GridOn";
// import Image from "@material-ui/icons/Image";
// import Place from "@material-ui/icons/Place";
// import Timeline from "@material-ui/icons/Timeline";
// import WidgetsIcon from "@material-ui/icons/Widgets";

var dashRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    rtlName: "لوحة القيادة",
    icon: DashboardIcon,
    component: Dashboard,
    layout: "/admin"
  },
  {
    collapse: true,
    name: "Events",
    rtlName: "صفحات",
    icon: "event_note",
    state: "pageCollapse",
    views: [
      {
        path: "/event-details",
        name: "Event List",
        rtlName: "صودعم رتل",
        mini: "E",
        rtlMini: "صو",
        component: EventDetails,
        layout: "/admin"
      },
      {
        path: "/manage-event",
        name: "Manage Event",
        rtlName: "عالتسعير",
        mini: "E",
        rtlMini: "ع",
        component: ManageEvents,
        layout: "/admin"
      },
      {
        path: "/reservations",
        name: "Reservations List",
        rtlName: "تيالجدول الزمني",
        mini: "R",
        rtlMini: "تي",
        component: Reservations,
        layout: "/admin"
      },
      {
        path: "/manage-reservation",
        name: "Manage Reservation",
        rtlName: "هعذاتسجيل الدخول",
        mini: "R",
        rtlMini: "هعذا",
        component: AddReservation,
        layout: "/admin"
      },
      {
        path: "/addons-list",
        name: "Addons List",
        rtlName: "اقفل الشاشة",
        mini: "A",
        rtlMini: "هذاع",
        component: AddonsList,
        layout: "/admin"
      },
      {
        path: "/manage-addons",
        name: "Manage Addons",
        rtlName: "تسجيل",
        mini: "A",
        rtlMini: "صع",
        component: ManageAddons,
        layout: "/admin"
      }
      // {
      //   path: "/user-page",
      //   name: "User Profile",
      //   rtlName: "ملف تعريفي للمستخدم",
      //   mini: "UP",
      //   rtlMini: "شع",
      //   component: UserProfile,
      //   layout: "/admin"
      // },
      // {
      //   path: "/error-page",
      //   name: "Error Page",
      //   rtlName: "صفحة الخطأ",
      //   mini: "E",
      //   rtlMini: "البريد",
      //   component: ErrorPage,
      //   layout: "/auth"
      // }
    ]
  },
  // {
  //   collapse: true,
  //   name: "Components",
  //   rtlName: "المكونات",
  //   icon: Apps,
  //   state: "componentsCollapse",
  //   views: [
  //     {
  //       collapse: true,
  //       name: "Multi Level Collapse",
  //       rtlName: "انهيار متعدد المستويات",
  //       mini: "MC",
  //       rtlMini: "ر",
  //       state: "multiCollapse",
  //       views: [
  //         {
  //           path: "/buttons",
  //           name: "Buttons",
  //           rtlName: "وصفت",
  //           mini: "B",
  //           rtlMini: "ب",
  //           component: Buttons,
  //           layout: "/admin"
  //         }
  //       ]
  //     },
  //     {
  //       path: "/buttons",
  //       name: "Buttons",
  //       rtlName: "وصفت",
  //       mini: "B",
  //       rtlMini: "ب",
  //       component: Buttons,
  //       layout: "/admin"
  //     },
  //     {
  //       path: "/grid-system",
  //       name: "Grid System",
  //       rtlName: "نظام الشبكة",
  //       mini: "GS",
  //       rtlMini: "زو",
  //       component: GridSystem,
  //       layout: "/admin"
  //     },
  //     {
  //       path: "/panels",
  //       name: "Panels",
  //       rtlName: "لوحات",
  //       mini: "P",
  //       rtlMini: "ع",
  //       component: Panels,
  //       layout: "/admin"
  //     },
  //     {
  //       path: "/sweet-alert",
  //       name: "Sweet Alert",
  //       rtlName: "الحلو تنبيه",
  //       mini: "SA",
  //       rtlMini: "ومن",
  //       component: SweetAlert,
  //       layout: "/admin"
  //     },
  //     {
  //       path: "/notifications",
  //       name: "Notifications",
  //       rtlName: "إخطارات",
  //       mini: "N",
  //       rtlMini: "ن",
  //       component: Notifications,
  //       layout: "/admin"
  //     },
  //     {
  //       path: "/icons",
  //       name: "Icons",
  //       rtlName: "الرموز",
  //       mini: "I",
  //       rtlMini: "و",
  //       component: Icons,
  //       layout: "/admin"
  //     },
  //     {
  //       path: "/typography",
  //       name: "Typography",
  //       rtlName: "طباعة",
  //       mini: "T",
  //       rtlMini: "ر",
  //       component: Typography,
  //       layout: "/admin"
  //     }
  //   ]
  // },
  {
    collapse: true,
    name: "Rooms",
    rtlName: "إستمارات",
    icon: "meeting_room",
    state: "formsCollapse",
    views: [
      {
        path: "/add-room",
        name: "Add Room",
        rtlName: "أشكال عادية",
        mini: "R",
        rtlMini: "صو",
        component: AddRoom,
        layout: "/admin"
      },
      {
        path: "/room-details",
        name: "Room Details",
        rtlName: "نماذج موسعة",
        mini: "R",
        rtlMini: "هوو",
        component: RoomDetails,
        layout: "/admin"
      }
      // {
      //   path: "/validation-forms",
      //   name: "Validation Forms",
      //   rtlName: "نماذج التحقق من الصحة",
      //   mini: "VF",
      //   rtlMini: "تو",
      //   component: ValidationForms,
      //   layout: "/admin"
      // },
      // {
      //   path: "/wizard",
      //   name: "Wizard",
      //   rtlName: "ساحر",
      //   mini: "W",
      //   rtlMini: "ث",
      //   component: Wizard,
      //   layout: "/admin"
      // }
    ]
  }
  // {
  //   collapse: true,
  //   name: "Tables",
  //   rtlName: "الجداول",
  //   icon: GridOn,
  //   state: "tablesCollapse",
  //   views: [
  //     {
  //       path: "/regular-tables",
  //       name: "Regular Tables",
  //       rtlName: "طاولات عادية",
  //       mini: "RT",
  //       rtlMini: "صر",
  //       component: RegularTables,
  //       layout: "/admin"
  //     },
  //     {
  //       path: "/extended-tables",
  //       name: "Extended Tables",
  //       rtlName: "جداول ممتدة",
  //       mini: "ET",
  //       rtlMini: "هور",
  //       component: ExtendedTables,
  //       layout: "/admin"
  //     },
  //     {
  //       path: "/react-tables",
  //       name: "React Tables",
  //       rtlName: "رد فعل الطاولة",
  //       mini: "RT",
  //       rtlMini: "در",
  //       component: ReactTables,
  //       layout: "/admin"
  //     }
  //   ]
  // },
  // {
  //   collapse: true,
  //   name: "Maps",
  //   rtlName: "خرائط",
  //   icon: Place,
  //   state: "mapsCollapse",
  //   views: [
  //     {
  //       path: "/google-maps",
  //       name: "Google Maps",
  //       rtlName: "خرائط جوجل",
  //       mini: "GM",
  //       rtlMini: "زم",
  //       component: GoogleMaps,
  //       layout: "/admin"
  //     },
  //     {
  //       path: "/full-screen-maps",
  //       name: "Full Screen Map",
  //       rtlName: "خريطة كاملة الشاشة",
  //       mini: "FSM",
  //       rtlMini: "ووم",
  //       component: FullScreenMap,
  //       layout: "/admin"
  //     },
  //     {
  //       path: "/vector-maps",
  //       name: "Vector Map",
  //       rtlName: "خريطة المتجه",
  //       mini: "VM",
  //       rtlMini: "تم",
  //       component: VectorMap,
  //       layout: "/admin"
  //     }
  //   ]
  // },
  // {
  //   path: "/widgets",
  //   name: "Widgets",
  //   rtlName: "الحاجيات",
  //   icon: WidgetsIcon,
  //   component: Widgets,
  //   layout: "/admin"
  // },
  // {
  //   path: "/charts",
  //   name: "Charts",
  //   rtlName: "الرسوم البيانية",
  //   icon: Timeline,
  //   component: Charts,
  //   layout: "/admin"
  // },
  // {
  //   path: "/calendar",
  //   name: "Calendar",
  //   rtlName: "التقويم",
  //   icon: DateRange,
  //   component: Calendar,
  //   layout: "/admin"
  // }
];
export default dashRoutes;
