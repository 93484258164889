import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import FormLabel from "@material-ui/core/FormLabel";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardText from "components/Card/CardText.js";
import CardBody from "components/Card/CardBody.js";

import styles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import CardFooter from "components/Card/CardFooter";
import { TextField } from "@material-ui/core";

import RoomImage1 from "assets/img/Room-1.jpg";
import RoomImage2 from "assets/img/Room-2.jpg";
import RoomImage3 from "assets/img/Room-3.jpg";
import RoomImage4 from "assets/img/Room-4.jpg";
import Tooltip from "@material-ui/core/Tooltip";
import Remove from "@material-ui/icons/Delete";

const useStyles = makeStyles(styles);

export default function AddRoom() {
  // const [checked, setChecked] = React.useState([24, 22]);
  // const [selectedEnabled, setSelectedEnabled] = React.useState("b");
  // const [selectedValue, setSelectedValue] = React.useState(null);
  // const handleChange = event => {
  //   setSelectedValue(event.target.value);
  // };
  // const handleChangeEnabled = event => {
  //   setSelectedEnabled(event.target.value);
  // };
  // const handleToggle = value => {
  //   const currentIndex = checked.indexOf(value);
  //   const newChecked = [...checked];

  //   if (currentIndex === -1) {
  //     newChecked.push(value);
  //   } else {
  //     newChecked.splice(currentIndex, 1);
  //   }
  //   setChecked(newChecked);
  // };
  const classes = useStyles();
  return (
    <>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="info" text>
              <CardText color="info">
                <h4 className={classes.cardTitle}>Room</h4>
              </CardText>
            </CardHeader>
            <CardBody>
              <form>
                <GridContainer>
                  <GridItem xs={12} sm={2}>
                    <FormLabel
                      className={classes.labelHorizontal}
                      style={{ paddingTop: "20px" }}
                    >
                      Room Name
                    </FormLabel>
                  </GridItem>
                  <GridItem xs={12} sm={10}>
                    <TextField
                      id="standard-name"
                      className={classes.textField}
                      fullWidth
                      margin="normal"
                    />
                  </GridItem>
                </GridContainer>
                {/* <GridContainer>
                <GridItem xs={12} sm={2}>
                  <FormLabel className={classes.labelHorizontal}>
                    Event type
                  </FormLabel>
                </GridItem>
                <GridItem xs={12} sm={10}>
                  <CustomInput
                    id="pass"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      type: "text",
                      autoComplete: "off"
                    }}
                  />
                </GridItem>
              </GridContainer> */}
                <GridContainer>
                  <GridItem xs={12} sm={2}>
                    <FormLabel className={classes.labelHorizontal}>
                      Room Description
                    </FormLabel>
                  </GridItem>
                  <GridItem xs={12} sm={10}>
                    <TextField
                      id="standard-name"
                      // label="Name"
                      className={classes.textField}
                      multiline
                      fullWidth
                      rows="3"
                      rowsMax="5"
                      // value={values.name}
                      // onChange={handleChange('name')}
                      margin="normal"
                    />
                  </GridItem>
                </GridContainer>
                {/* <GridContainer>
                <GridItem xs={12} sm={2}>
                  <FormLabel className={classes.labelHorizontal}>
                    Price
                  </FormLabel>
                </GridItem>
                <GridItem xs={12} sm={10}>
                  <CustomInput
                    id="disabled"
                    formControlProps={{
                      fullWidth: true
                    }}
                  />
                </GridItem>
              </GridContainer> */}
                <GridContainer>
                  <GridItem xs={12} sm={2}>
                    <FormLabel
                      className={classes.labelHorizontal}
                      style={{ paddingTop: "20px" }}
                    >
                      Images
                    </FormLabel>
                  </GridItem>
                  <GridItem xs={12} sm={10}>
                    {/* <CustomInput
                    id="disabled"
                    formControlProps={{
                      fullWidth: true
                    }}  
                    inputProps={{
                      type: "file",
                      autoComplete: "off"
                    }}                
                  /> */}
                    <Button
                      className={classes.UploadImageField}
                      containerElement="label" // <-- Just add me!
                      label="My Label"
                      onChange={target => {
                        console.log(target, "event");
                      }}
                    >
                      <input
                        type="file"
                        multiple
                        onChange={target => {
                          console.log(target, "event");
                        }}
                      />
                    </Button>
                  </GridItem>
                </GridContainer>
              </form>
            </CardBody>
            <CardFooter style={{ justifyContent: "flex-end" }}>
              <Button color="info">Add Room</Button>
            </CardFooter>
          </Card>
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={12} sm={12} md={3}>
          <Card product className={classes.cardHover}>
            <CardHeader image className={classes.cardHeaderHover}>
              <a href="#pablo" onClick={e => e.preventDefault()}>
                <img src={RoomImage1} alt="..." />
              </a>
            </CardHeader>
            <CardBody>
              <div className={classes.RemoveButton}>
                <Tooltip
                  id="tooltip-top"
                  title="Remove"
                  placement="bottom"
                  classes={{ tooltip: classes.tooltip }}
                >
                  <Button color="danger" simple justIcon>
                    <Remove className={classes.underChartIcons} />
                  </Button>
                </Tooltip>
              </div>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={3}>
          <Card product className={classes.cardHover}>
            <CardHeader image className={classes.cardHeaderHover}>
              <a href="#pablo" onClick={e => e.preventDefault()}>
                <img src={RoomImage2} alt="..." />
              </a>
            </CardHeader>
            <CardBody>
              <div className={classes.RemoveButton}>
                <Tooltip
                  id="tooltip-top"
                  title="Remove"
                  placement="bottom"
                  classes={{ tooltip: classes.tooltip }}
                >
                  <Button color="danger" simple justIcon>
                    <Remove className={classes.underChartIcons} />
                  </Button>
                </Tooltip>
              </div>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={3}>
          <Card product className={classes.cardHover}>
            <CardHeader image className={classes.cardHeaderHover}>
              <a href="#pablo" onClick={e => e.preventDefault()}>
                <img src={RoomImage3} alt="..." />
              </a>
            </CardHeader>
            <CardBody>
              <div className={classes.RemoveButton}>
                <Tooltip
                  id="tooltip-top"
                  title="Remove"
                  placement="bottom"
                  classes={{ tooltip: classes.tooltip }}
                >
                  <Button color="danger" simple justIcon>
                    <Remove className={classes.underChartIcons} />
                  </Button>
                </Tooltip>
              </div>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={3}>
          <Card product className={classes.cardHover}>
            <CardHeader image className={classes.cardHeaderHover}>
              <a href="#pablo" onClick={e => e.preventDefault()}>
                <img src={RoomImage4} alt="..." />
              </a>
            </CardHeader>
            <CardBody>
              <div className={classes.RemoveButton}>
                <Tooltip
                  id="tooltip-top"
                  title="Remove"
                  placement="bottom"
                  classes={{ tooltip: classes.tooltip }}
                >
                  <Button color="danger" simple justIcon>
                    <Remove className={classes.underChartIcons} />
                  </Button>
                </Tooltip>
              </div>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </>
  );
}
