import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// material-ui icons
import Assignment from "@material-ui/icons/Assignment";
// import Person from "@material-ui/icons/Person";
import Edit from "@material-ui/icons/Edit";
import Close from "@material-ui/icons/Close";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Table from "components/Table/Table.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";

import styles from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.js";

import { Link } from "react-router-dom";

const useStyles = makeStyles(styles);

export default function ExtendedTables() {
  // const [checked, setChecked] = React.useState([]);
  // const handleToggle = value => {
  //   const currentIndex = checked.indexOf(value);
  //   const newChecked = [...checked];

  //   if (currentIndex === -1) {
  //     newChecked.push(value);
  //   } else {
  //     newChecked.splice(currentIndex, 1);
  //   }
  //   setChecked(newChecked);
  // };
  const classes = useStyles();
  const fillButtons = [
    // { color: "info", icon: Person },
    { color: "info", icon: Edit, url: "manage-reservation" },
    { color: "danger", icon: Close }
  ].map((prop, key) => {
    return (
      <Button
        color={prop.color}
        className={classes.actionButton}
        key={key}
        href={prop.url}
      >
        <prop.icon className={classes.icon} />
      </Button>
    );
  });
  // const simpleButtons = [
  //   { color: "info", icon: Person },
  //   { color: "info", icon: Edit },
  //   { color: "danger", icon: Close }
  // ].map((prop, key) => {
  //   return (
  //     <Button
  //       color={prop.color}
  //       simple
  //       className={classes.actionButton}
  //       key={key}
  //     >
  //       <prop.icon className={classes.icon} />
  //     </Button>
  //   );
  // });
  // const roundButtons = [
  //   { color: "info", icon: Person },
  //   { color: "success", icon: Edit },
  //   { color: "danger", icon: Close }
  // ].map((prop, key) => {
  //   return (
  //     <Button
  //       round
  //       color={prop.color}
  //       className={classes.actionButton + " " + classes.actionButtonRound}
  //       key={key}
  //     >
  //       <prop.icon className={classes.icon} />
  //     </Button>
  //   );
  // });
  return (
    <>
      <div style={{ textAlign: "right" }}>
        <Button color="info">Export as Excel <i class="fa fa-file-excel" aria-hidden="true" style={{ fontSize: "0.8rem", marginLeft: 10 }}></i> </Button>
        <Button color="info">
          <Link to="/manage-event" style={{ color: "inherit" }}>
            Add Reservation 
          </Link>
        </Button>
      </div>
      <GridContainer>
        <GridItem xs={12} md={12}>
          <Card>
            <CardHeader color="info" icon>
              <CardIcon color="info">
                <Assignment />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>Reservation Details</h4>
            </CardHeader>
            <CardBody>
              <Table
                tableHead={["#", "Name", "Actions"]}
                tableData={[
                  ["1", "test", fillButtons],
                  ["2", "test", fillButtons],
                  ["3", "test", fillButtons],
                  ["4", "test", fillButtons],
                  ["5", "test", fillButtons]
                ]}
                customCellClasses={[
                  classes.center,
                  classes.right,
                  classes.right
                ]}
                customClassesForCells={[0, 4, 5]}
                customHeadCellClasses={[
                  classes.center,
                  classes.right,
                  classes.right
                ]}
                customHeadClassesForCells={[0, 4, 5]}
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </>
  );
}
